/**
 * @description Create
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Grid, Item, Label } from 'semantic-ui-react';
import CommitteeChair from '../../Committees/CommitteeChair';
import { flattenToAppURL } from '@plone/volto/helpers';

const CommitteeListTemplate = ({ items }) => {
  return (
    <Grid columns={2}>
      {items.map((item, index) => {
        console.log(item);
        return (
          <Grid.Column key={`committee-${index}`}>
            <Item.Group className="committee-item" relaxed>
              <Item className="committee-overview-wrap">
                <Item.Content verticalAlign="middle">
                  <Item.Header as="a" href={item['@id']}>
                    {item.title}
                  </Item.Header>

                  <Item.Description>
                    <Label.Group>
                      {/*
                    {item.subjects.map((tag, index) => (
                      <Label key={index}>{tag}</Label>
                    ))}{' '}
                    */}
                    </Label.Group>
                    {item.description}
                  </Item.Description>
                </Item.Content>
              </Item>
              <div>
                <div className="committee-wrap">
                  <a
                    className="button committee-readmore-button"
                    href={item['@id']}
                  >
                    Read more
                  </a>
                </div>
                <div className="committee-chairs">
                  <h2 className="committee-chairs-heading">
                    Committee Leadership
                  </h2>
                  {item.committee_chair_name && (
                    <CommitteeChair
                      chairPosition="chair"
                      chairTitle={item.committee_chair_title}
                      chairName={item.committee_chair_name}
                      contactButton={
                        item.hasChairVcard
                          ? `${item.getURL}/@@download/committee_chair_vcard`
                          : null
                      }
                      chairPhoto={
                        item.image_scales?.committee_chair_photo?.[0]?.scales
                          ?.tile.download
                          ? `${item.getURL}/${item.image_scales.committee_chair_photo[0].scales.tile.download}`
                          : undefined
                      }
                      chairFirm={item.committee_chair_firm}
                      chairLocation={item.chair_location}
                    />
                  )}
                  {item.committee_vice_chair_name && (
                    <CommitteeChair
                      chairPosition="co-chair"
                      contactButton={
                        item.hasViceChairVcard
                          ? `${item.getURL}/@@download/committee_vice_chair_vcard`
                          : null
                      }
                      chairPhoto={
                        item.image_scales?.committee_vice_chair_photo?.[0]
                          ?.scales?.tile.download
                          ? `${item.getURL}/${item.image_scales.committee_vice_chair_photo[0].scales.tile.download}`
                          : undefined
                      }
                      chairTitle={item.committee_vice_chair_title}
                      chairName={item.committee_vice_chair_name}
                      chairFirm={item.committee_vice_chair_firm}
                      chairLocation={item.vice_chair_location}
                    />
                  )}
                </div>
              </div>
            </Item.Group>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

CommitteeListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CommitteeListTemplate;
