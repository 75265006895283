import React, { useEffect, useState } from 'react';
import CommitteeChair from './CommitteeChair';
import './committee.css';
import { Input, Icon, Grid, Item, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';

const Committees = (props) => {
  const dispatch = useDispatch();
  const committees = useSelector((state) => state.search.items);
  const [term, setTerm] = useState('');
  const [dropDownFilter, setDropDownFilter] = useState({ value: '' });

  useEffect(() => {
    console.log('Fetching committees...');
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['committee'],
          fullobjects: true,
          review_state: 'open',
          sort_on: 'getObjPositionInParent',  // Add this line
        },
        term,
      ),
    );
  }, [dispatch, term]);

  useEffect(() => {
    console.log('Committees:', committees);
  }, [committees]);

  const updateSearchResults = (e) => {
    setTerm(e.target.value);
  };

  const getTags = (committees) => {
    return [
      ...new Set(
        [].concat.apply([], committees.map((item) => item.subjects)),
      ),
    ].map((itx, index) => ({ key: index, value: itx, text: itx }));
  };

  return (
    <div>
      <Input fluid icon placeholder="Search Committees">
        <Icon name="search" />
        <input
          type="text"
          className="prompt"
          value={term}
          onChange={updateSearchResults}
        />
      </Input>
      <Grid className="committee-list">
        <Grid.Row columns={2} className="committee-list-grid">
          {committees &&
            committees.map((item, index) => (
              <Grid.Column key={index}>
                <Item.Group className="committee-item" relaxed>
                  <Item className="committee-overview-wrap">
                    <Item.Content verticalAlign="middle">
                      <Item.Header as="a" href={item['@id']}>
                        {item.title}
                      </Item.Header>
                      <Item.Description>
                        <Label.Group>
                          {/*
                          {item.subjects.map((tag, index) => (
                            <Label key={index}>{tag}</Label>
                          ))}{' '}
                          */}
                        </Label.Group>
                        {item.description}
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <div>
                    <div className="committee-wrap">
                      <a
                        className="button committee-readmore-button"
                        href={item['@id']}
                      >
                        Read more
                      </a>
                    </div>
                    <div className="committee-chairs">
                      <h2 className="committee-chairs-heading">
                        Committee Leadership
                      </h2>
                      {item.committee_chair_name  && (
                        <CommitteeChair
                          chairPosition="chair"
                          chairPhoto={
                            item.committee_chair_photo?.scales.thumb.download
                          }
                          contactButton={item?.committee_chair_vcard?.download}
                          chairTitle={item.committee_chair_title}
                          chairName={item.committee_chair_name }
                          chairFirm={item.committee_chair_firm}
                          chairLocation={item.committee_chair_location}
                        />
                      )}
                      {item.committee_vice_chair_name && (
                        <CommitteeChair
                          chairPosition="co-chair"
                          chairPhoto={
                            item.committee_vice_chair_photo?.scales.thumb.download
                          }
                          contactButton={
                            item?.committee_vice_chair_vcard?.download
                          }
                          chairTitle={item.committee_vice_chair_title}
                          chairName={item.committee_vice_chair_name}
                          chairFirm={item.committee_vice_chair_firm}
                          chairLocation={item.committee_vice_chair_location}
                        />
                      )}
                    </div>
                  </div>
                </Item.Group>
              </Grid.Column>
            ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Committees;
