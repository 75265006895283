import React from 'react';
import StopHand from './stop_hand.svg';
import './accessdenied.css';

const AccessDenied = (props) => {
  const { loginUrl, joinUrl, message } = props;
  return (
    <div className="access-denied-container">
      <div className="access-denied-access-denied">
        <div className="access-denied-accessdeniedheader">
          <img
            src={StopHand}
            alt="stophand"
            className="access-denied-stophand"
          />
          <div className="access-denied-frame-wrap">
            <span className="access-denied-message-heading">
              <span>Access Denied</span>
            </span>
            <span className="access-denied-text">
              <span>
                You are trying to access a members-only area of our site.
              </span>
            </span>
          </div>
        </div>
        <div className="access-denied-accesdeniedcontent">
          <span className="access-denied-subheading">
            <span>Already a Member?</span>
          </span>
          <span className="access-denied-text">
            <span>
              If you are a member of the section, you should already have access
              to this content.
            </span>
          </span>
        </div>
        <div className="access-denied-groupjoinandlogin">
          <a href={loginUrl} className="access-denied-button-login">
            <span className="access-denied-login-text">
              <span>Login</span>
            </span>
          </a>
          <a href={joinUrl} className="access-denied-button-join">
            <span className="access-denied-join-text">
              <span>Join the Section*</span>
            </span>
          </a>
        </div>
        <div className="access-denied-frame">
          <span className="access-denied-text-cost">
            * it only costs $30 per year to join
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
